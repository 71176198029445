import React from "react"
import { Link } from "gatsby"
import * as styles from "./book-ad-card.module.scss"

export default function BookAdCard({ bookAd }) {
    return (
        <article className={styles.bookAdCard}>
            <Link to={bookAd.urlAmazon}>
                <div className={styles.bookAdCardInnerContainer}>
                    <picture className={styles.bookAdCardThumbnailBox}>
                        <source type="image/webp" srcSet={bookAd.thumbnailImage.thumbnailWebp.url} />
                        <img
                            className={styles.bookAdCardThumbnailImage}
                            src={bookAd.thumbnailImage.thumbnailJpeg.url}
                            width="150"
                            height="150"
                            alt={bookAd.name}
                            decoding="async"
                        />
                    </picture>
                    <section className={styles.bookAdCardInfo}>
                        <h2 className={styles.bookAdCardName}>{bookAd.name}</h2>
                        <section className={styles.bookAdCardLinks}>
                          { bookAd.urlAmazon && <Link to={bookAd.urlAmazon} className={styles.bookAdCardLink} >Amazon</Link> }
                          { bookAd.urlKindle && <Link to={bookAd.urlKindle} className={styles.bookAdCardLink} >Kindle</Link> }
                          { bookAd.urlRakutenBooks && <Link to={bookAd.urlRakutenBooks} className={styles.bookAdCardLink} >Rakuten</Link> }
                        </section>
                    </section>
                </div>
            </Link>
        </article>
    )
}
