import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BookAdCard from "../components/book-ad-card"
import * as styles from "./book.module.scss"

export default function Book({ data, location }) {
    var bookAds = data.wagtailbase.bookAds
    bookAds.sort(function(a, b) {
        if(a.id < b.id) return 1;
        if(a.id > b.id) return -1;
        return 0;
    });

    return (
        <Layout location={location}>
            <SEO
                title="おすすめ本"
            />
            <article>
                <header className={styles.bookListHeader}>
                    <h1>
                        おすすめ本
                    </h1>
                    <p>プログラマにおすすめの本をまとめています</p>
                </header>
                <section className={styles.bookAdCardsContainer}>
                    {bookAds.map(bookAd => {
                        return <BookAdCard bookAd={bookAd} key={bookAd.id} />
                    })}
                </section>
            </article>
        </Layout>
    )
}


export const query = graphql`
    query {
        wagtailbase {
            bookAds {
              id
              name
              urlAmazon
              urlKindle
              urlRakutenBooks
              thumbnailImage {
                url
                thumbnailWebp: rendition(min: "200x200", format: "webp") {
                  url
                }
                thumbnailJpeg: rendition(min: "200x200", format: "jpeg") {
                  url
                }
              }
            }
        }
    }
`
